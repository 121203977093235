import React, { useEffect, useState, useRef } from "react";
// import emailjs from "@emailjs/browser";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FullColumn } from "../../../components/utility/rowColumn";
// import Navbar from "../../Navbar";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCarousel,
  CCarouselCaption,
  CCarouselItem,
  CCol,
  CRow,
} from "@coreui/react";
import supplyChainManagement1 from "../../../assests/supplyChainManagement1.jpeg";
import supplyChainManagement2 from "../../../assests/supplyChainManagement2.jpg";
import supplyChainManagement4 from "../../../assests/supplyChainManagement4.jpg";
import supplyChainManagement7 from "../../../assests/supplyChainManagement7.jpg";
import AngularImg from "../../../assests/angular.jpg";
import ReactImg from "../../../assests/react.jpg";
import VueImg from "../../../assests/vue.jpg";
import axios from "axios";
import { logigloUrl } from "../../Config/index";
import DocsExample from "./DocsExample";
import "./index.css";

const TopCarousel = () => {
  const [carouselData, setCarouselData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const images = [
    supplyChainManagement4,
    supplyChainManagement1,
    supplyChainManagement7,
    
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <div className="top-carousel-outer-con" style={{marginBottom : '-50px'}}>
      <CCol xs={12}>
        <DocsExample href="components/carousel/#crossfade" className="top-carousel-inner-con">
          <CCarousel
            transition="crossfade"
            activeIndex={activeIndex}
            interval={2000}
          >
            {images.map((image, index) => (
              <CCarouselItem key={index}>
                <img
                  className="d-block w-100"
                  src={image}
                  alt={`slide ${index + 1}`}
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                />
                

              </CCarouselItem>
              
              
            ))}
          </CCarousel>
          <CCarousel  transition="crossfade" interval={2000}  activeIndex={activeIndex}>
            <CCarouselItem >
              <img className="d-block w-100" src={ReactImg} alt="slide 1" />
              <CCarouselCaption className="d-none d-md-block">
                <h5>
                  Global Business & Supply Chain Marketplace & Professional
                  Community
                </h5>
                <p>
                  Access Experts, Solutions, Resources, Podcasts, Offers,
                  Masterclasses & Events.
                </p>
              </CCarouselCaption>
            </CCarouselItem>
            <CCarouselItem>
              <img className="d-block w-100" src={AngularImg} alt="slide 2" />
              <CCarouselCaption className="d-none d-md-block">
                <h5>Express your thoughts and ideas related to Supply Chain</h5>
                <p>
                  Access Experts, Solutions, Resources, Podcasts, Offers,
                  Masterclasses & Events.
                </p>
              </CCarouselCaption>
            </CCarouselItem>
            <CCarouselItem>
              <img className="d-block w-100" src={VueImg} alt="slide 3" />
              <CCarouselCaption className="d-none d-md-block">
                <h5>Welcome to Supply Chain Partners</h5>
                <p>
                  Achieving Your Business & Supply Chain Performance Goals Just
                  Got Easier...
                </p>
              </CCarouselCaption>
            </CCarouselItem>
          </CCarousel>
        </DocsExample>
      </CCol>
    </div>
  );
};

export default TopCarousel;
