import React, { useEffect, useState, useRef } from "react";
// import emailjs from "@emailjs/browser";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FullColumn } from "../../../components/utility/rowColumn";
import axios from "axios";
import { logigloUrl } from "../../Config/index";
import CustomIcons from "../../../assests/messages";
// import { MdOutlineForum } from "react-icons/md";
import { FaForumbee } from "react-icons/fa";
import useAOS from "../../scrollAnimation";
import Aos from "aos";
import "aos/dist/aos.css";

import "./index.css";
import { duration } from "moment";

const WhatWeDoSection = () => {
  const [
    whatWeDoCategorySectionMainTextDetails,
    setwhatWeDoCategorySectionMainTextDetails,
  ] = useState([]);
  const [whatWeDoCategorySection, setwhatWeDoCategorySection] = useState([]);
  const token = useSelector((state) => state.Auth.idToken);
  const accountId = useSelector((state) => state.Auth.accountId);
  
  useAOS();


  let accountType = null;

  // Check if token exists in localStorage
  const storedToken = localStorage.getItem("token");
  if (storedToken) {
    const parsedToken = JSON.parse(storedToken);
    // Check if accountType exists in parsedToken
    if (parsedToken && parsedToken.accountType) {
      accountType = parsedToken.accountType;
    }
  }







   // Fetch whatWeDoSection data from the backend when the component mounts
   useEffect(() => {
    axios
      .get(`${logigloUrl}/whatWeDoSection`)
      .then((response) => {
        const whatWeDoSectiontextDetails = response.data.whatWeDoSection
        const whatWeDoSectionContentDetails = response.data.whatWeDoSectionContent
        console.log(whatWeDoSectiontextDetails, "whatWeDoSection");
        setwhatWeDoCategorySectionMainTextDetails(whatWeDoSectiontextDetails);
        setwhatWeDoCategorySection(whatWeDoSectionContentDetails);
      
      })
      .catch((error) => {
        console.error("Error fetching whatWeDoSection data:", error);
      });
  }, []);



  return (
    <div data-aos="fade-up" className="what-we-do-sec-outer-container">
     
      <div className="what-we-do-category-section-outer-con">
        {whatWeDoCategorySectionMainTextDetails.map((eachItem) => (
          <div className="what-we-do-sec-main-header-con">
            <p className="section-header-styles">{eachItem.header}</p>
            <p className="section-title-styles">{eachItem.title}</p>
          </div>
        ))}

        <div className="all-icons-container">
         
          {whatWeDoCategorySection.map((eachItem) => (
            <div className="icon-and-text-con" >
            <Link
            to={eachItem.navigation === '/forum' && token ? `/forumDashboard?account_id=${accountId}` : eachItem.navigation}
              className="each-whah-we-do-sec-icon-con"
            >
      
                <CustomIcons base64={eachItem.icon} />
         
            </Link>
                <p>{eachItem.title}</p>
                </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatWeDoSection;
