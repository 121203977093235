import React, { useEffect, useState, useRef } from "react";
// import emailjs from "@emailjs/browser";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FullColumn } from "../../../components/utility/rowColumn";
import axios from "axios";
import { logigloUrl } from "../../Config/index";
import CustomIcons from "../../../assests/messages";
// import { MdOutlineForum } from "react-icons/md";
import { FaForumbee } from "react-icons/fa";
import useAOS from "../../scrollAnimation";
import Aos from "aos";
import "aos/dist/aos.css";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import { differenceInHours, formatDistanceToNow, parseISO } from "date-fns";
import "./index.css";
import { duration } from "moment";

import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardGroup,
  CCardHeader,
  CCardImage,
  CCardLink,
  CCardSubtitle,
  CCardText,
  CCardTitle,
  CListGroup,
  CListGroupItem,
  CNav,
  CNavItem,
  CNavLink,
  CCol,
  CRow,
} from "@coreui/react";

const RecentPostsCard = () => {
  const [posts, setPosts] = useState([]);
  const jwtToken = localStorage.getItem("token");
  const history = useHistory();

  console.log(jwtToken, "jt_token");

  console.log(posts, "posts");

  let account_id, accountType;

  if (jwtToken) {
    const tokeData = JSON.parse(jwtToken);
    account_id = tokeData.accountId;
    accountType = tokeData.accountType;
  }

  useAOS();
  // Posts with pagination
  const fetchData = async (page) => {
    const pageNumber = isNaN(page) ? 1 : parseInt(page);

    try {
      const url = `${logigloUrl}/getAllPostsData?page=${pageNumber}`;

      const response = await axios.get(url);
      console.log(response.data, "res_err_check")

      const limitedPosts = response.data.posts.slice(0, 3);

      setPosts(limitedPosts);
    } catch (error) {
      console.error(error);
    }
  };

  console.log(posts, "pp");

  useEffect(() => {
    fetchData(1);
  }, []);

  const onClickViewAllBtn = (post) => {
    if (jwtToken) {
      // If jwtToken is present, navigate to createReply page
      history.push(
        `/forumDashboard?account_id=${account_id}&accountType=${accountType}`
      );
    } else {
      // If jwtToken is not present, navigate to login page
      history.push(
        `/forum?account_id=${account_id}&accountType=${accountType}`
      );
    }
  };




  const onClickPostTitle = (post) => {
    // console.log(post, "clicked", "uiuity")

     // Convert post object to string
     const postString = JSON.stringify(post);
    
     // Store post details in local storage
     localStorage.setItem('selectedPost', postString);

    // console.log("clocloclo")
   
      if (jwtToken) {
        // If jwtToken is present, navigate to createReply page
        history.push(
          `/forumDashboard/createReply`
        );
      } else {
        // If jwtToken is not present, navigate to login page
        history.push("/signin");
      }
   
  };

  // Function to get the first n letters from the text
  let post_text_to_display;
let getFirstNLetters;

const handleWindowSizeChange = () => {
  if (window.innerWidth > 2000) {
    post_text_to_display = 90;
    getFirstNLetters = (text, n) => {
      return text.slice(0, n) + (text.length > n ? "..." : "");
    };
  } else {
    post_text_to_display = 50;
    getFirstNLetters = (text, n) => {
      return text.slice(0, n) + (text.length > n ? "..." : "");
    };
  }
};

// Initial setup
handleWindowSizeChange();

// Add event listener for window resize
window.addEventListener('resize', handleWindowSizeChange);

  // Function to format date and time
  const formatDateTime = (dateString) => {
    const dateObject = new Date(dateString);
    return format(dateObject, "dd/MM/yyyy, hh:mm:ss a", { locale: enUS });
  };

  // Function to calculate the time difference and return the display text
  const getTimeDisplayText = (postCreatedAt) => {
    const createdTime = parseISO(postCreatedAt);
    const currentTime = new Date();

    const timeDifferenceInSeconds = Math.floor(
      (currentTime - createdTime) / 1000
    );

    if (timeDifferenceInSeconds < 60) {
      // If post is created within the last minute, display "X seconds ago"
      return ` ${timeDifferenceInSeconds} second${
        timeDifferenceInSeconds !== 1 ? "s" : ""
      } ago`;
    } else if (timeDifferenceInSeconds < 3600) {
      // If post is created within the last hour, display "X minutes ago"
      const minutesAgo = Math.floor(timeDifferenceInSeconds / 60);
      return ` ${minutesAgo} minute${minutesAgo !== 1 ? "s" : ""} ago`;
    } else if (
      timeDifferenceInSeconds >= 3600 &&
      timeDifferenceInSeconds < 86400
    ) {
      // If post is created more than an hour ago, display "X hours ago"
      const hoursAgo = Math.floor(timeDifferenceInSeconds / 3600);
      const remainingMinutes = Math.floor(
        (timeDifferenceInSeconds % 3600) / 60
      );
      return ` ${hoursAgo} hour${
        hoursAgo !== 1 ? "s" : ""
      } ${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""} ago`;
    } else {
      // If post is created more than 1 day ago, display formatted date and time
      return formatDateTime(postCreatedAt);
    }
  };

  return (
    <div className="recent-postscard-outer-conn" data-aos="fade-up">
      <div style={{ width: "100%",}}>
        <div className="recent-postscard-inner-con">
          <div className="first-carousel-head-con">
            <div className="left-container">
              <p className="what-wedo-header">Recent Posts</p>
            </div>
            <div className="right-container">
              {/* Any content you want for the right container */}
            </div>
          </div>

          {/* <h3 className="recent-posts-title">Recent Posts</h3> */}
          <button onClick={onClickViewAllBtn} className="view-all-Btn-styles">
            View All
          </button>
        </div>
        <CRow xs={{ cols: 1, gutter: 4 }} md={{ cols: 3 }} >
          {posts.map((eachPosts) => (
            <CCol xs key={eachPosts.id} >
              <CCard className="each-card-styles recent-post-card-con"  onClick={() => onClickPostTitle(eachPosts)}>
                {/* <CCardImage orientation="top" src={ReactImg} /> */}
                <CCardBody>
                  <CCardTitle onClick={() => onClickPostTitle(eachPosts)} className="recent-post-title-styles" >
                    {getFirstNLetters(eachPosts.title, post_text_to_display)}
                  </CCardTitle>
                  <CCardText>
                    {getFirstNLetters(eachPosts.text, post_text_to_display)}
                  </CCardText>
                </CCardBody>
                <CCardFooter>
                  <small className="text-body-secondary">
                    {" "}
                    Posted {getTimeDisplayText(eachPosts.created_at)}{" "}
                  </small>
                </CCardFooter>
              
               
              </CCard>
            
            </CCol>
          ))}

          {/* <div className="post-card-con" key={eachPosts.id}>
        <p onClick={onClickPostTitle(eachPosts.id)} className="post-title-styles">{getFirstNLetters(eachPosts.title, post_text_to_display)}</p>
        <p className="post-description-styles">{getFirstNLetters(eachPosts.text, post_text_to_display)}</p>
        <div className="read-more" onClick={onClickPostTitle(eachPosts.id)}>
                Read More
              </div>
        </div> */}

          {/* <CCol xs>
                  <CCard>
                    <CCardBody>
                      <CCardTitle>Card title</CCardTitle>
                      <CCardText>
                        This is a wider card with supporting text below as a natural lead-in to
                        additional content. This content is a little bit longer.
                      </CCardText>
                    </CCardBody>
                    <CCardFooter>
                      <small className="text-body-secondary">Last updated 3 mins ago</small>
                    </CCardFooter>
                  </CCard>
                </CCol> */}
        </CRow>
      </div>
    </div>
  );
};

export default RecentPostsCard;
